<template>
    <Card title="Services">
        <table class="table table-hover">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Fa Icon</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in services" :key="item.id">
                    <td>{{ item.title }}</td>
                    <td><i :class="'fa fa-' + item.fa_icon" style="width: 2.4em"></i></td>
                    <td><button role="button" class="btn btn-link btn-sm" @click="$router.push({ name: 'AdminServicesEdit', params: { item } })">edit</button></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="99">
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-outline-success" @click="$router.push({ name: 'AdminServicesEdit' })">
                                <i class="fas fa-plus"></i> CREATE NEW Service
                            </button>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    </Card>
</template>

<script>
import firebase from '../../../firebase'
import Card from '@/components/admin/Card.vue'
export default {
    components: { Card },
    data() {
        return {
            services: []
        }
    },
    methods: {
    },
    mounted() {
        firebase.firestore().collection('services').onSnapshot(({ docs }) => {
            this.services = docs.map(doc => {
                console.log(doc.data())
                return { ...doc.data(), id: doc.id }
            })
        });
    }
}
</script>